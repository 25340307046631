import React, { useContext, useEffect, useState } from 'react';
import UserContext from "../UserContext"

const CountDown = () => {
    const [countdown, setCountdown] = useState(3);
    const countdownNumbers = [3, 2, 1, "GO"]
    const { user } = useContext(UserContext)
    const countdownBody = "radial-gradient(circle,#fff, #333)";
    document.body.style.backgroundImage = countdownBody
    useEffect(() => {
      let index = 0
      const timer = setInterval(() => {
        setCountdown(countdownNumbers[index])
        index++
        if (index >= countdownNumbers.length) {
          clearInterval(timer);
          document.body.style.backgroundColor = "#FF4D49"
          document.body.style.backgroundImage = null;
          user.page = "realrun"
        }
      }, 1000)
      return () => clearInterval(timer)
    }, [])
  

  return (
    <>
      <div className="container--countdown">
        <div className="countdown">{countdown}</div>
      </div>
    </>
  )
}

export default CountDown
