import React, { useContext } from 'react'
import defaultImage from './../../../statics/images/default.jpg'
import goldImage from './../../../statics/images/gold.jpg'
import purpleImage from './../../../statics/images/purple.jpg'
import { useNavigate } from 'react-router-dom'
import UserContext from "../UserContext"
import Header from '../Header'



const SelectTheme = () => {
  const navigate = useNavigate()
  const { user, setUser } = useContext(UserContext)
  const handlePreview = (themeName) => {
    navigate("/demo")
    user.step = 5
    user.theme = themeName
  }
  const handleSelect = (themeName) => {
    console.log("themeName is ", themeName)
    navigate("/confirm")
    user.step = 5
    user.theme = themeName
    user.page='confirm'
    console.log("user is", user)
  }
  return (
    <>
      <Header />
      <main className="whitebox template">
        <section className="template__box">
          <button className="template__image">
            <img src={defaultImage} alt="default" />
            <button
              onClick={() => handleSelect("default")}
              className="template__selectbutton"
            >
              انتخاب
            </button>
          </button>
          <button
            onClick={() => handlePreview("default")}
            className="btn btn-primary template__demobutton"
          >
            پیش نمایش
          </button>
        </section>
        <section className="template__box ">
          <button className="template__image">
            <img src={goldImage} alt="gold" />
            <button
              onClick={() => handleSelect("gold")}
              className="template__selectbutton select--dark"
            >
              انتخاب
            </button>
          </button>
          <button
            onClick={() => handlePreview("gold")}
            className="btn btn-primary template__demobutton"
          >
            پیش نمایش
          </button>
        </section>
        <section className="template__box">
          <button className="template__image">
            <img src={purpleImage} alt="gold" />
            <button
              onClick={() => handleSelect("purple")}
              className="template__selectbutton select--dark"
            >
              انتخاب
            </button>
          </button>
          <button
            onClick={() => handlePreview("purple")}
            className="btn btn-primary template__demobutton"
          >
            پیش نمایش
          </button>
        </section>
      </main>
    </>
  )
}

export default SelectTheme
