import { retrieveLaunchParams } from "@telegram-apps/sdk-react"



console.log("NODE_ENV:", process.env.NODE_ENV)
function getApiUrl(){
  if (process.env.NODE_ENV === "development") {
    return "http://127.0.0.1:5050"
  } else {
    return "https://qorekeshi.hyper-design.ir"
  }

}
export const apiUrl = getApiUrl()
console.log("apiUrl:", apiUrl)

export function getInitDataRaw(){
    
  if (process.env.NODE_ENV === "development") {
    const initDataRaw =
      "query_id=AAErJVANAAAAACslUA2zLbJZ&user=%7B%22id%22%3A223356203%2C%22first_name%22%3A%22%D8%B7%D8%B1%D8%A7%D8%AD%DB%8C%20%D8%B1%D8%A8%D8%A7%D8%AA%20%D8%AA%D9%84%DA%AF%D8%B1%D8%A7%D9%85%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22hyperdesign%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1725625847&hash=1fab046b5a4392d3f9f8fa25e37dc1ca3e5be6f24a48992d5491d556a97716bd"
    return initDataRaw
  } else {
    const { initDataRaw } = retrieveLaunchParams()
    return initDataRaw
  }
};
