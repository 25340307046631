import { useEffect, useRef, useState } from "react"


const DisplayUsers = ({ listNames, winners }) => {
  const [scrollable, setScrollable] = useState(false)
  const myRef = useRef(null)

  useEffect(() => {
    const offsetHeight = myRef.current?.offsetHeight
    const scrollHeight = myRef.current?.scrollHeight
    if (offsetHeight === scrollHeight) {
      setScrollable(true)
    }
  }, [listNames])

  

  return (
    <>
      <ul>
        {listNames.map((name, index) => {
          if (index === 3 && winners.includes(name)) {
            return (
              <li key={index} style={{ direction: "ltr" }}>
                <h1 className="winner-highlight">{name}</h1>
              </li>
            )
          }
          return (
            <li key={index} style={{ direction: "ltr" }}>
              {name}
            </li>
          )
        })}
      </ul>
    </>
  )
}

export default DisplayUsers