import React, { createContext, useState } from "react"
import { ReactMediaRecorder } from "react-media-recorder-2"

export const ScreenRecordingContext = createContext()

export const ScreenRecordingProvider = ({ children }) => {
  const [isRecording, setIsRecording] = useState(false)
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null)
  console.log("ScreenRecordingProvider")
  const handleStartRecording = (startFunc) => {
    console.log("start recording")
    startFunc()
    setIsRecording(true)
  }

  const handleStopRecording = (stopFunc, mediaBlobUrl) => {
    console.log("handle stop")
    console.log("stopFunc", stopFunc)
    stopFunc()
    setIsRecording(false)
    console.log("media:", mediaBlobUrl)
    setMediaBlobUrl(mediaBlobUrl)
  }

  return (
    <ReactMediaRecorder
      screen
      audio
      render={({ status, startRecording, stopRecording, mediaBlobUrl }) => (
        <ScreenRecordingContext.Provider
          value={{
            isRecording: status,
            startRecording: () => handleStartRecording(startRecording),
            stopRecording: () =>
              handleStopRecording(stopRecording, mediaBlobUrl),
            mediaBlobUrl: mediaBlobUrl,
          }}
        >
          {children}
        </ScreenRecordingContext.Provider>
      )}
    />
  )
}
