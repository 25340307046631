import React, { useContext, useEffect, useState, useRef } from "react";
import UserContext from "../UserContext";
import _, { flatMap, set } from "lodash";
import Header from "../Header";
import DisplayUsers from "./DisplayUsers";
import DisplayWinners from "./DisplayWinners";
import v2 from '../../../statics/audio/v2.mp3';  // Adjust the path relative to your component
import v1 from '../../../statics/audio/v1.mp3'; 

import {availableThemes, names} from '../../../utils/Helper'

const ShowUsers = ({ demo}) => {
  const initIndex = 10
  const { user, setUser } = useContext(UserContext);
  // const names = user.listMembers.map((item) => item.fullname);
  const [listNames, setListNames] = useState(names.slice(0, initIndex));
  const [offset, setOffSet] = useState(1);
  const [winners, setWinners] = useState([]);
  const [showWinners, setShowWinners] = useState(false);
  const [neigberIndices, setIneigberIndices] = useState([]);
  const [sleepTime, setSleepTime] = useState(100);
  const [startLotto, setStartLotto] = useState(false);
  const music2Ref = useRef(null);
  const music1Ref = useRef(null);

  useEffect (()=>{
    document.body.style.backgroundColor = "#FF4D49"
    document.body.style.backgroundImage = null
  }, []);

  

  useEffect(() => {
    if (!startLotto) {
      return
    }
    if (neigberIndices.includes(offset)) {
      setSleepTime(1000);
      music1Ref.current.pause();
      music2Ref.current.play();
    } else {
      setSleepTime(100)
      music1Ref.current.play()
      music2Ref.current.pause()
      music2Ref.current.currentTime = 0
    }
  }, [offset])

  // Select winners before starting the rotation
  useEffect(() => {
    const listWinners = _.sampleSize(names, user.countWinner)
    setWinners(listWinners)
  }, [])
  useEffect(() => {
    if (!startLotto) {
      return
    }
    setOffSet((prevOffset) => prevOffset + 1)
    setTimeout(() => {
      if (showWinners) {
        return
      }
      const listSlice = names.slice(offset, offset + initIndex)
      setListNames(listSlice)
      if (listNames.length === 0) {
        setShowWinners(true)
      }
      const indices = winners
        .map((winner) => names.indexOf(winner))
        .filter((index) => index > 1)
      const neigberIndices = indices.flatMap((number) =>
        [number - 6, number - 5, number - 4, number - 3, number - 2].filter(
          (index) => index > 1
        )
      )
      setIneigberIndices(neigberIndices)
    }, sleepTime)
    return () => clearTimeout()
  }, [listNames,startLotto])

  const theme = user.theme
  const themeClass = availableThemes.includes(theme) ? theme : "default"
  console.log("thethemeClassme:", themeClass)
  const handleClick =()=>{
    console.log("111111111");
    setStartLotto(true);
    console.log('2222222');
    music1Ref.current.play();
    console.log('33333333');
  }
  return (
    <>
      <Header />
      <main class={`container ${themeClass} theme`}>
        <section class={`users ${themeClass}--users`}>
          {showWinners ? (
            <DisplayWinners winners={winners} demo={demo}></DisplayWinners>
          ) : (
            <DisplayUsers winners={winners} listNames={listNames} />
          )}
        </section>
        {!startLotto && <button onClick={handleClick}>Start</button>}
      </main>
      <audio ref={music1Ref} src={v1}></audio>
      <audio ref={music2Ref} src={v2}></audio>
    </>
  )
}

export default ShowUsers
