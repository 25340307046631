import React, { useContext, useEffect } from 'react'
import UserContext from "./UserContext"
import { useNavigate } from 'react-router-dom'

const Header = () => {
  const navigate = useNavigate()
  const { user, setUser } = useContext(UserContext)
  
  const handlebackButton = () => {
    const newStep = user["step"] - 1
    console.log("newStep is ", newStep)
    setUser((prevUserInfo) => ({
      ...prevUserInfo,
      step: newStep,
    }));
    if (newStep <=0) {
      setUser((prevUserInfo) => ({
        ...prevUserInfo,
        step: 1,
      }))
    }
    if (newStep <= 1) {
      navigate("/")
    }
    if (newStep === 2) {
      navigate("/getlink")
    }
    if (newStep === 3) {
      navigate("/exceptusers")
    }
    if (newStep === 4) {
      navigate("/countwinners")
    }
    if (newStep === 5) {
      navigate("/select")
    }
    if (newStep === 6) {
      navigate("/confirm")
    }
    console.log("newStep ", newStep)
  }
  return (
    <>
      <header>
        <button className="backbutton" onClick={handlebackButton}>
          {/* <svg
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" id="Left">
            <path
              d="M25 1C11.767 1 1 11.767 1 25s10.767 24 24 24 24-10.767 24-24S38.233 1 25 1zm0 46C12.869 47 3 37.131 3 25S12.869 3 25 3s22 9.869 22 22-9.869 22-22 22z"
              fill="#555"
              class="color000000 svgShape"
            ></path>
            <path
              d="M29.293 10.293 14.586 25l14.707 14.707 1.414-1.414L17.414 25l13.293-13.293z"
              fill="#fff"
              class="color000000 svgShape"
            ></path>
        </svg> */}
          <svg
            fill="#000"
            opacity='0.8'
            height="800px"
            width="800px"
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 300.003 300.003"
            // xml:space="preserve"
          >
            <g>
              <g>
                <path
                  d="M150,0C67.159,0,0.001,67.159,0.001,150c0,82.838,67.157,150.003,149.997,150.003S300.002,232.838,300.002,150
			C300.002,67.159,232.839,0,150,0z M189.226,218.202c-2.736,2.734-6.321,4.101-9.902,4.101c-3.582,0-7.169-1.367-9.902-4.103
			l-56.295-56.292c-0.838-0.537-1.639-1.154-2.368-1.886c-2.796-2.799-4.145-6.479-4.077-10.144
			c-0.065-3.667,1.281-7.35,4.077-10.146c0.734-0.731,1.53-1.349,2.368-1.886l56.043-56.043c5.47-5.465,14.34-5.467,19.808,0.003
			c5.47,5.467,5.47,14.335,0,19.808l-48.265,48.265l48.514,48.516C194.695,203.864,194.695,212.732,189.226,218.202z"
                />
              </g>
            </g>
          </svg>
        </button>
      </header>
    </>
  )
}

export default Header
