import { apiUrl, getInitDataRaw } from "./Variables"
const initDataRaw = getInitDataRaw();

export function getCredit({ user }) {
  
  const sendRequest = async () => {
    const end_point = "/get_balance/"
    const url = apiUrl + end_point
    const formData = {
      user_id: user.chatid,
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `tma ${initDataRaw}`,
        },
        body: JSON.stringify(formData),
      })
      const data = await response.json()
      console.log("data:", data)
      if (data["status"] === "failed") {
        const message = data["message"]
        return message
      } else {
        return data["balance"]
      }
    } catch (error) {
      console.error("Error:", error)
      return false
    }
  }
  return sendRequest()
}

export function updateCredit({ user, amount }) {
  const sendRequest = async () => {
    const end_point = "/update_balance/"
    const url = apiUrl + end_point
    const formData = {
      user_id: user.chatid,
      amount: amount,
    }
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `tma ${initDataRaw}`,
        },
        body: JSON.stringify(formData),
      })
      const data = await response.json()
      console.log("data:", data)
      if (data["status"] === "failed") {
        const message = data["message"]
        return message
      } else {
        return data["balance"]
      }
    } catch (error) {
      console.error("Error:", error)
      return false
    }
  }
  return sendRequest()
}
