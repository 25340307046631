import React, { useContext } from 'react'
import UserContext from "../UserContext"

const AlertCredit = ({ price, setShowCreditPopup }) => {
  const { user, setUser } = useContext(UserContext)
  
  const handleGotIt = () => {
    setShowCreditPopup(false)
  }
  
  return (
    <section class="alert-record">
      <p>
        اعتبار شما کافی نیست برای اجرای قرعه کشی باید {price.toLocaleString()} {" "}
        تومان اعتبار داشته باشید
      </p>
      <button onClick={handleGotIt} class="alert-record__button nowrap">
        متوجه شدم
      </button>
      <button class="alert-record__button nowrap secondary">شارژ حساب</button>
    </section>
  )
}

export default AlertCredit
