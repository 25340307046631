import { apiUrl, getInitDataRaw } from "../../../utils/Variables"

export function validateLink({user}) {
  const initDataRaw = getInitDataRaw()

  console.log("validateLink")

  function persianErrorMessage({ message}) {
    console.log("error is", message)
    if (message.includes("The channel parameter is invalid")) {
      console.log("persianErrorMessage error:", message)
      return "ربات را ادمین کانال/گروه نمایید"
    }

    if (message.includes("USERNAME_INVALID")) {
      return "لینک صحیح نیست"
    } 

    return message
  }

  const sendRequest = async () => {
    const end_point = "/chat_members/"
    const url = apiUrl + end_point
    console.log("channel link is", user.channelLink)
    const formData = {
      link: user.channelLink,
    }
    console.log("formData: ", formData)
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `tma ${initDataRaw}`,
        },
        body: JSON.stringify(formData),
      })
      const data = await response.json()
      
      if (data["status"] === "failed") {
        const message = data['message']
        const persia_message = persianErrorMessage({ message})
        return [false, persia_message]

      } else {
        const persia_message = ""
        console.log("data:", data)
        user.title = data["title"]
        user.listMembers = data["list_members"]
        console.log("user is:", user)
        return [true, persia_message]
      }
    } catch (error) {
      console.error("Error:", error)
      return [false, error]
    }
  }

  return sendRequest()
}
