import "bootstrap/dist/css/bootstrap.css"
// Put any other imports below so that CSS from your
// components takes precedence over default styles.
import React from "react"
import "./index.css"
import App from "./App"
import "./statics/fonts/Vazirmatn-Regular.ttf" 
import { createRoot } from "react-dom/client"
const container = document.getElementById("root")
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById("root")
)
