

export function getModelPersian({ user }) {
  if (user["lotteryModel"] === "group") {
    return "بین اعضای گروه"
  }
  return "بین اعضای کانال"
}
export function getThemePersian({ user }) {
  if (user.theme === "default") {
    return "پیش فرض"
  }
  if (user.theme === "gold") {
    return "طلایی"
  }
  if (user.theme === "purple") {
    return "ارغوانی"
  }
  return "پیش فرض"
}

export function getPrice(lotteryModel) {
  if (lotteryModel === "all-group") {
    return 20000
  }
  if (lotteryModel === "all-channel") {
    return 20000
  }
  return false
}

export const availableThemes = ["purple", "gold", "default"]

export const names = [
  "Ali",
  "سمیرا",
  "سارا",
  "John",
  "مریم",
  "David",
  "نینا",
  "Michael",
  "رضا",
  "Jessica",
  "Mohammad",
  "اما",
  "Layla",
  "Robert",
  "Fatemeh",
  "ویلیام",
  "Ava",
  "Richard",
  "Hossein",
  "Sophia",
  "Arash",
  "Thomas",
  "Tara",
  "Charles",
  "Zahra",
  "George",
  "Parisa",
  "Daniel",
  "Neda",
  "Matthew",
  "Leila",
  "Andrew",
  "Mona",
  "Christopher",
  "Yasmin",
  "Joshua",
  "Samira",
  "Anthony",
  "Arezoo",
  "Joseph",
  "Shirin",
  "Kenneth",
  "Elham",
  "Steven",
  "Negar",
  "Paul",
  "Roya",
  "Edward",
  "Fariba",
  "Brian",
  "Saman",
  "Ronald",
  "Mehrdad",
  "Timothy",
  "Mitra",
  "Jason",
  "Dariush",
  "Jeffrey",
  "Mahsa",
  "Ryan",
  "Soraya",
  "Jacob",
  "Farzaneh",
  "Gary",
  "Amin",
  "Nicholas",
  "Setareh",
  "Eric",
  "Behnaz",
  "Stephen",
  "Shahab",
  "Larry",
  "Kian",
  "Justin",
  "Ramin",
  "Scott",
  "Hoda",
  "Brandon",
  "Fereshteh",
  "Benjamin",
  "Kaveh",
  "Samuel",
  "Sahar",
  "Gregory",
  "Simin",
  "Frank",
  "Milad",
  "Patrick",
  "Raha",
  "Raymond",
  "Nima",
  "Jack",
  "Sepideh",
  "Jerry",
  "Kamran",
  "Alexander",
  "Mina",
  "Dennis",
  "Farhad",
  "Henry",
  "Pardis",
  "Carl",
  "Shahram",
  "Arthur",
  "Yalda",
  "Walter",
  "Elham",
  "Peter",
  "Shirin",
  "Harold",
  "Keyvan",
  "Douglas",
  "Golnaz",
  "Lawrence",
  "Pouya",
  "Terry",
  "Dorsa",
  "Sean",
  "Babak",
  "Jesse",
  "Sadaf",
  "Christian",
  "Amir",
  "Ethan",
  "Maryam",
  "Austin",
  "Pouneh",
  "Joe",
  "Bahram",
  "Albert",
  "Nasim",
  "Bryan",
  "Kiarash",
  "Bruce",
  "Niloufar",
  "Willie",
  "Sohrab",
  "Jordan",
  "Azadeh",
  "Dylan",
  "Ashkan",
  "Alan",
  "Mahtab",
  "Ralph",
  "Khashayar",
  "Gabriel",
  "Shadi",
  "Roy",
  "Behzad",
  "Juan",
  "Raha",
  "Wayne",
  "Mehrnaz",
  "Billy",
  "Hamid",
  "Vincent",
  "Nasrin",
  "Randy",
  "Parviz",
  "Russell",
  "Mojgan",
  "Louis",
  "Nader",
  "Philip",
  "Roxana",
  "Bobby",
  "Sasan",
  "Johnny",
  "Shadi",
  "Bradley",
  "Pejman",
  "Curtis",
  "Zohreh",
  "Walter",
  "Abolfazl",
  "Lawrence",
  "Roshanak",
  "Ralph",
  "Ebrahim",
  "Jerry",
  "Mehrdad",
  "Tyler",
  "Pegah",
  "Aaron",
  "Kasra",
  "Jose",
  "Rana",
  "Adam",
  "Davood",
  "Nathan",
  "Golnoosh",
  "Keith",
  "Arman",
  "Zachary",
  "Elaheh",
  "Patrick",
  "Koorosh",
  "Harry",
  "Nahal",
  "Gerald",
  "Jamshid",
  "Ray",
  "Shabnam",
  "Jeremy",
  "Payam",
  "Albert",
  "Marjan",
  "Jack",
  "Ehsan",
  "Scott",
  "Sanaz",
  "Derek",
  "Hassan",
  "Kyle",
  "Simin",
  "Francis",
  "Armin",
  "Danny",
  "Goli",
  "Philip",
  "Niousha",
  "Kirk",
  "Bijan",
  "Joshua",
  "Nooshin",
  "Tony",
  "Kourosh",
  "Caleb",
  "Shirin",
  "Stanley",
]
