import React, { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import UserContext from "../UserContext"
import Header from "../Header"
import { apiUrl, getInitDataRaw } from "../../../utils/Variables"


const MainPage = () => {
  const { user, setUser } = useContext(UserContext)
  const initDataRaw = getInitDataRaw();
  console.log("init data: ", initDataRaw)
  const getUser = async () => {
    const end_point = "/get_user/"
    const url = apiUrl + end_point
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `tma ${initDataRaw}`,
        },
      })
      const data = await response.json()
      console.log("Response data:", data)
      setUser((prevUserInfo) => ({
        ...prevUserInfo,
        chatid: data["user"]["id"],
      }))
      console.log("user:", user)
    } catch (error) {
      console.log("error:", error)
    }
  }
  const navigate = useNavigate();
  
  
  useEffect(() => {
    getUser()
    if (
      typeof window !== "undefined" &&
      window.Telegram &&
      window.Telegram.WebApp
    )
      // webApp.MainButton.setText("برگشت به ربات").show()
      console.log("ok");
  }, [])
  

  const setModelGroup = (event, lotteryModel) => {
    console.log("lotteryModel:", lotteryModel)
    event.preventDefault()
    navigate("/getlink", { replace: true })
    setUser((prevUserInfo) => ({
      ...prevUserInfo,
      lotteryModel: lotteryModel,
      step: 2,
    }))
  }

  return (
    <>
      <Header />
      <main class="container model">
        <h2 class="model__header">
           <span class="nowrap">قصد برگزاری چه  نوع قرعه کشی را دارید؟ </span>  
        </h2>
        <div className="model__buttons">
          <button
            class="button btn--selectmodel"
            onClick={(e) => setModelGroup(e, "all-group")}
          >
            بین اعضای گروه
          </button>
          <button
            class="button btn--selectmodel"
            onClick={(e) => setModelGroup(e, "all-channel")}
          >
            بین اعضای کانال
          </button>
        </div>
      </main>
    </>
  )
}

export default MainPage
