import { useRef } from "react";
import ShowUsers from "../RunLottery/ShowUsers";
import v1 from '../../../statics/audio/v1.mp3';  // Adjust the path relative to your component


const DemoRun =()=>{
   const music1Ref = useRef(null)
    
    return (
      <>
        <audio ref={music1Ref} src={v1}></audio>
        <ShowUsers music1Ref={music1Ref} demo={true} />
      </>
    )
}
export default DemoRun;