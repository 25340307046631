import React, { useContext, useState} from "react"

import Header from '../Header'
import UserContext from "../UserContext"
import { getModelPersian, getThemePersian, getPrice } from "../../../utils/Helper"
import { getCredit } from "../../../utils/TelLotteryApi"
import AlertCredit from './AlertCredit'
import AlertRecord from "./AlertRecord"

const ConfirmLottery = () => {
  const webApp = window.Telegram.WebApp
  const { user, setUser } = useContext(UserContext)
  const [showCreditPopup, setShowCreditPopup] = useState(false)
  const [showRecordPopup, setShowRecordPopup] = useState(false)

  const price = getPrice(user["lotteryModel"])

  const modelLottery = getModelPersian({ user })
  const themeLottery = getThemePersian({ user })

  console.log("user is", user)

  const handleRunLottery = async (e) => {
    const credit = await getCredit({ user })
    // const credit = 2000000
    if (!credit) {
      webApp.showAlert("خطا در برقرار ارتباط با سرور")
      return
    }
    console.log("credit is", credit)
    if (credit < price) {
      setShowCreditPopup(true)
      return
    } else {
      user.page = "countdown";
      user.play = true;
      // music1Ref.current.play();
    }
  }

  return (
    <>
      <Header />
      <main className="confirm--container">
        <article className="info">
          <h4 className="info__header">اطلاعات قرعه کشی</h4>
          <ul className="info__details">
            <li style={{ direction: "rtl" }}>نام گروه: {user["title"]}</li>
            <li>نوع قرعه کشی: {modelLottery}</li>
            <li>تعداد شرکت کنندگان: {user["listMembers"].length} کاربر</li>
            <li>تعداد برندگان: {user["countWinner"]} یوزر</li>
            <li>تم: {themeLottery}</li>
            <li>تعداد کاربران استثنا: {user["exceptUsers"].length} یوزر</li>
          </ul>
        </article>
        <div className="tip">
          <section className="tip__text">
            <p>
              با زدن دکمه ی زیر، {price.toLocaleString()} تومان از حساب شما کم
              شده و قرعه کشی اجرا میشود
            </p>
            {/* <p style={{ direction: "rtl" }}>
              برای ضبط فیلم در حین <span className="nowrap">قرعه کشی</span>،{" "}
              <span className="nowrap">ابتدا دکمه ی</span> ضبط را بزنید.
            </p> */}
          </section>
          <section className="tip__buttons">
            <button onClick={handleRunLottery} className="btn--confirm">
              اجرای <span className="nowrap">قرعه کشی</span>
            </button>
            {/* <ScreenRecording /> */}
            <p className="text-danger">
              برای ضبط فیلم در حین قرعه کشی از اپلیکیشن مخصوص پلتفرم خود استفاده
              کنید
            </p>
          </section>
        </div>
        {showCreditPopup && (
          <AlertCredit price={price} setShowCreditPopup={setShowCreditPopup} />
        )}
        {showRecordPopup && (
          <AlertRecord setShowRecordPopup={setShowRecordPopup} />
        )}
      </main>
    </>
  )
}

export default ConfirmLottery
