import React, { useContext, useEffect } from 'react'
import { ScreenRecordingContext } from "../ScreenRecordingContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faPaperPlane } from "@fortawesome/free-solid-svg-icons"
import Header from '../Header'
import UserContext from "../UserContext"


const DownloadVideo = () => {
  const { user, setUser } = useContext(UserContext)
  const { isRecording, startRecording, stopRecording, mediaBlobUrl } =
      useContext(ScreenRecordingContext)
  
  useEffect(()=>{
    setUser((prevUserInfo) => ({
      ...prevUserInfo,
      step: 1,
    }))
  }, [])
  
  stopRecording()

  return (
    <>
      <Header />
      <div className="whitebox frame">
        <div className="frame__box">
          <video className="frame__video" src={mediaBlobUrl} controls />

          <a
            href={mediaBlobUrl}
            download="recording.webm"
            className="btn btn-primary btn--download"
            style={{ color: "white" }}
          >
            دانلود ویدیو &nbsp;
            <FontAwesomeIcon icon={faDownload} />
          </a>
          <button className="btn btn-secondary btn--sendgroup">
            ارسال به گروه &nbsp;
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>
      </div>
    </>
  )
}

export default DownloadVideo
