import React, { useContext } from "react"
import UserContext from "../UserContext"

const AlertRecord = ({ setShowRecordPopup }) => {
  const { user, setUser } = useContext(UserContext)
  const handleGotIt = () => {
    setShowRecordPopup(false)
  }
  return (
    <section class="alert-record">
      <p>
        این قابلیت در دستگاه شما پشتیبانی نمیشود لطفا برای ضبط فیلم،از اپلیکیشن
        داخل گوشی استفاده نمایید
      </p>
      <button onClick={handleGotIt} class="alert-record__button nowrap">
        متوجه شدم
      </button>
      <button class="alert-record__button nowrap secondary">اطلاعات بیشتر</button>
    </section>
  )
}

export default AlertRecord;
