import React, { useContext, useEffect, useState } from "react"
import Header from "../Header"
import { useNavigate } from "react-router-dom"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import UserContext from "../UserContext"
import { faXmark } from "@fortawesome/free-solid-svg-icons/faXmark"
import { Color } from "antd/es/color-picker"

const ShowExcepts = () => {
  const navigate = useNavigate()
  const { user, setUser } = useContext(UserContext)
  const [exceptUser, setExceptUser] = useState("")
  const [listExceptUsers, setListExceptUsers] = useState(user["exceptUsers"])
  const [isEmpty, setIsEmpty] = useState(false)

  useEffect(() => {
    if (listExceptUsers.length === 0) {
      setIsEmpty(true)
    } else {
      setIsEmpty(false)
    }
  }, [listExceptUsers])

  const handleRemoveExcept = (id) => {
    setListExceptUsers((prevList) => {
      const updatedList = prevList.filter((a) => a.id !== id)
      return updatedList
    })
  }

  const handleAddExcept = (e) => {
    e.preventDefault()
    if (!exceptUser) {
      
      return
    }
    const newList = listExceptUsers.concat({
      id: crypto.randomUUID(),
      name: exceptUser,
    })
    setListExceptUsers(newList)
    setExceptUser("")
  }
  
  const handleSubmit = (e) =>{
    console.log("user is", user)
    e.preventDefault()
    setUser((prevUserInfo) => ({
      ...prevUserInfo,
      exceptUsers: listExceptUsers,
      step:4,
    }))
    navigate("/countwinners", { replace: true })
  }
  const handleSkip = ()=>{
    setListExceptUsers([])
    setUser((prevUserInfo) => ({
      ...prevUserInfo,
      exceptUsers: listExceptUsers,
      step: 4,
    }))
    navigate("/countwinners", { replace: true })
  }
  const style_hidden ={
    display:'none'
  }
  return (
    <>
      <Header />
      <main className="whitebox exceptusers ">
        <section className="exceptusers__header">
          <p style={{ direction: "rtl" }}>
            اگر قرار است قرعه‌کشی دوم را برگزار کنید و نمی‌خواهید اسم برندگان
            قرعه‌کشی قبلی تکرار شود، لطفاً اسم‌های قرعه‌کشی قبلی را در این لیست
            قرار دهید.
            <br />
            ❗️ لطفاً با دقت و اخلاقیات مناسب از این بخش استفاده کنید. با تشکر
            از شما!
            <br />
            <a
              onClick={handleSkip}
              className="exceptusers__skip--link"
              href="#"
            >
              رد شو
            </a>
          </p>

          <button onClick={handleSkip} className="exceptusers__skip--button">
            رد شو
          </button>
        </section>
        <form>
          <div className="exceptusers__form">
            <input
              placeholder="@hyperdesign"
              autoFocus="on"
              value={exceptUser}
              type="text"
              className="exceptusers__input"
              onChange={(e) => {
                setExceptUser(e.target.value)
              }}
            />
            <button
              onClick={handleAddExcept}
              className="exceptusers__add btnshadow"
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </form>
      
        <section
          className="exceptusers__list"
          style={isEmpty ? style_hidden : {}}
        >
          <div>
            {isEmpty ? (
              <p>لیست خالی است</p>
            ) : (
              <ul className="exceptusers__box">
                {listExceptUsers.map((user) => (
                  <li key={user["id"]} className="exceptusers__row">
                    <button
                      onClick={() => {
                        handleRemoveExcept(user["id"])
                      }}
                      className="exceptusers__remove"
                    >
                      <div className="exceptusers__xmark">
                        <FontAwesomeIcon icon={faXmark} />
                        &nbsp; &nbsp;
                        <span className="exceptusers__item">
                          {user["name"]}
                        </span>
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </section>
        <div
          className="exceptusers__confirm"
          style={isEmpty ? style_hidden : {}}
        >
          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit}
          >
            تایید
          </button>
        </div>
      </main>
    </>
  )
}
export default ShowExcepts
